/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';

import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { MAX_NB_CHARS_FOR_AUDIENCES_NAME } from 'embercom/services/ai-content-segments-service';
import { action } from '@ember/object';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import { put } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { helper } from '@ember/component/helper';
import type { LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import {
  type BulkActionApiParams,
  type ContentFragmentTuple,
} from 'embercom/components/knowledge-hub/filterable-list/bulk-actions/bulk-action-helper';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';

interface Args {
  localizedKnowledgeContent: LocalizedKnowledgeContent;
}

export default class Dropdown extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare intl: any;
  @service declare permissionsService: any;
  @service declare router: RouterService;
  @service declare notificationsService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.aiContentSegmentsService.loadSegments();
  }

  get segments() {
    return this.aiContentSegmentsService.segments;
  }

  get selectedSegmentIds() {
    return this.args.localizedKnowledgeContent.aiContentSegmentIds || [];
  }

  get dropdownLabel() {
    return this.selectedSegmentIds?.length
      ? this.firstSelectedSegmentName
      : this.intl.t('knowledge-hub.content-editor.shared.audience-section.dropdown.everyone');
  }

  get firstSelectedSegmentName(): string | undefined {
    let firstSegmentName = this.segments.find(
      (segment) => this.selectedSegmentIds[0] === Number(segment.id),
    )?.nameWithFallback;

    if (firstSegmentName && firstSegmentName?.length > MAX_NB_CHARS_FOR_AUDIENCES_NAME) {
      firstSegmentName = `${firstSegmentName.substring(0, MAX_NB_CHARS_FOR_AUDIENCES_NAME)}...`;
    }

    if (this.selectedSegmentIds.length === 1) {
      return firstSegmentName;
    }

    return this.intl.t(
      'knowledge-hub.content-editor.shared.audience-section.dropdown.additional-audience-segments',
      {
        segmentName: firstSegmentName,
        count: this.selectedSegmentIds.length - 1,
      },
    );
  }

  segmentIsAppliedToSelection = helper(function ([segment, selectedSegmentIds]: [
    AiContentSegment,
    Array<number>,
  ]): boolean {
    return selectedSegmentIds.includes(Number(segment.id));
  });

  @tracked activeSegments: Array<AiContentSegment> = [];
  @tracked removingAudiences = false;

  get contentTuples(): [ContentFragmentTuple] {
    return [
      {
        entity_id: this.args.localizedKnowledgeContent.entityId,
        entity_type: this.args.localizedKnowledgeContent.entityType,
      },
    ];
  }

  @action async removeAllSegments() {
    try {
      await this.checkPermissionWithPopup();
    } catch (e) {
      return;
    }
    this.removingAudiences = true;
    let allSegmentIds = this.segments.map((segment) => Number(segment.id));
    await this.addOrRemoveSegments([], allSegmentIds, true);
    this.args.localizedKnowledgeContent.aiContentSegmentIds.removeObjects(allSegmentIds);
    this.removingAudiences = false;
  }

  async checkPermissionWithPopup() {
    await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
  }

  @action redirectToAudienceSettings() {
    let url = this.router.urlFor('apps.app.settings.audiences', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  @action async applySegment(segment: AiContentSegment) {
    try {
      await this.checkPermissionWithPopup();
    } catch (e) {
      return;
    }

    if (this.activeSegments.includes(segment)) {
      return;
    }
    this.activeSegments.pushObject(segment);

    let segmentIdsToAdd: Array<number> = [];
    let segmentIdsToDelete: Array<number> = [];

    if (this.selectedSegmentIds.includes(Number(segment.id))) {
      segmentIdsToDelete = [Number(segment.id)];
    } else {
      segmentIdsToAdd = [Number(segment.id)];
    }

    await this.addOrRemoveSegments(segmentIdsToAdd, segmentIdsToDelete);

    if (segmentIdsToAdd.length) {
      this.args.localizedKnowledgeContent.aiContentSegmentIds.addObjects(segmentIdsToAdd);
    }

    if (segmentIdsToDelete.length) {
      this.args.localizedKnowledgeContent.aiContentSegmentIds.removeObjects(segmentIdsToDelete);
    }

    this.activeSegments.removeObject(segment);
  }

  @action async addOrRemoveSegments(
    segmentIdsToAdd: Array<number>,
    segmentIdsToDelete: Array<number>,
    isAudienceReset = false,
  ) {
    let params: BulkActionApiParams = {
      query: null,
      unselected_tuples: null,
      app_id: this.appService.app.id,
      segment_ids_to_add: segmentIdsToAdd,
      segment_ids_to_delete: segmentIdsToDelete,
      contents_to_update: this.contentTuples,
      is_bulk_selection: false,
      remove_all_segments: isAudienceReset,
    };

    await put(`/ember/knowledge_base/bulk_actions/bulk_change_segment`, params);

    this.notificationsService.notifyConfirmation(
      this.intl.t(`knowledge-hub.filterable-list.bulk-actions.update-audience.sync-success-banner`),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Audience::Dropdown': typeof Dropdown;
    'knowledge-hub/content-editor/shared/audience/Dropdown': typeof Dropdown;
  }
}
