/* import __COLOCATED_TEMPLATE__ from './select-content-type.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type IntlService from 'ember-intl/services/intl';
import {
  AI_AGENT_ENTITY_TYPES,
  AI_COPILOT_ENTITY_TYPES,
  FinAIType,
} from 'embercom/lib/knowledge-hub/constants';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type { ViewId } from '../../setup-modal';
interface Args {
  modal: any;
  title: string;
  onNavigationBack: () => void;
  onNavigationNext: (viewId: ViewId) => void;
  showBackButton: boolean;
  onModalClose: () => void;
  context?: {
    folder?: string;
    entityTypes?: EntityType[];
    aiTypeFilter?: FinAIType;
    includeNonKnowledgeBaseContent?: boolean;
  };
}

const CONTENT_ENTITY_TYPES = [
  EntityType.ArticleContent,
  EntityType.Answer,
  EntityType.InternalArticle,
  EntityType.ContentSnippet,
];

const SYNC_ENTITY_TYPES = [
  EntityType.ArticleContent,
  EntityType.InternalArticle,
  EntityType.ExternalContent,
  EntityType.FileSourceContent,
];

export default class SelectContentType extends Component<Args> {
  @service declare router: any;
  @service declare intercomEventService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare permissionsService: any;
  @service declare intl: IntlService;

  get contentEntityTypes() {
    return this.filterDisplayableEntityTypes(CONTENT_ENTITY_TYPES);
  }

  get syncEntityTypes() {
    return this.filterDisplayableEntityTypes(SYNC_ENTITY_TYPES);
  }

  private filterDisplayableEntityTypes(entityTypesToDisplay: EntityType[]) {
    let entityTypes: EntityType[] | null = null;
    if (this.args.context?.aiTypeFilter === FinAIType.COPILOT) {
      entityTypes = AI_COPILOT_ENTITY_TYPES;
    } else if (this.args.context?.aiTypeFilter === FinAIType.AGENT) {
      entityTypes = AI_AGENT_ENTITY_TYPES;
    } else if (this.args.context?.entityTypes) {
      entityTypes = this.args.context?.entityTypes;
    }

    if (entityTypes) {
      entityTypesToDisplay = entityTypesToDisplay.filter((entityType) =>
        entityTypes?.includes(entityType),
      );
    }
    if (!this.args.context?.includeNonKnowledgeBaseContent) {
      entityTypesToDisplay = entityTypesToDisplay.filter(
        (entityType) => entityType !== EntityType.Answer,
      );
    }
    return entityTypesToDisplay;
  }

  get contentTiles() {
    let tiles = this.contentEntityTypes.map((entityType) => {
      return {
        id: `${entityType}`,
        title: this.intl.t(
          `knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.${objectNames[entityType]}.title`,
        ),
        entityType,
        description: this.intl.t(
          `knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.${objectNames[entityType]}.description`,
        ),
      };
    });
    return tiles;
  }

  get importTiles() {
    let tiles = [
      {
        id: 'zendesk',
        viewId: 'zendesk-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.zendesk.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/zendesk.png'),
        entityType: EntityType.ArticleContent,
      },
      {
        id: 'guru',
        viewId: 'guru-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.guru.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/guru.png'),
        entityType: EntityType.InternalArticle,
      },
      {
        id: 'notion',
        viewId: 'notion-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.notion.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/notion.png'),
        entityType: EntityType.InternalArticle,
      },
      {
        id: 'confluence',
        viewId: 'confluence-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.confluence.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/confluence.png'),
        entityType: EntityType.InternalArticle,
      },
      {
        id: 'external-content',
        viewId: 'external-content',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.external-content.title',
        ),
        entityType: EntityType.ExternalContent,
      },
      {
        id: 'file-upload',
        viewId: 'file-upload',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.file.title',
        ),
        entityType: EntityType.FileSourceContent,
      },
    ].filter((tile) => this.syncEntityTypes.includes(tile.entityType));
    return tiles;
  }

  @action async navigateToView(viewId: ViewId, entityType: EntityType) {
    try {
      await this.knowledgeHubService.ensurePermissions(entityType);
    } catch (error) {
      this.args.onModalClose();
      return;
    }
    this.args.onNavigationNext(viewId);
  }

  @action async createContent(entityType: EntityType) {
    this.knowledgeHubService.createContent(entityType, { folder_id: this.args.context?.folder });
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SelectContentType': typeof SelectContentType;
    'knowledge-hub/setup-modal/select-content-type': typeof SelectContentType;
  }
}
