/* import __COLOCATED_TEMPLATE__ from './manage-macros-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';
import type CopilotApi from 'embercom/services/copilot-api';
import type IntlService from 'ember-intl/services/intl';
import { localCopy } from 'tracked-toolbox';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

export interface Args {
  onClose: () => void;
  useMacros: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class ManageMacrosModal extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare copilotApi: CopilotApi;
  @service declare notificationsService: any;
  @service declare knowledgeHubService: KnowledgeHubService;

  @localCopy('args.useMacros') declare useMacros: boolean;
  @tracked hasEdited = false;

  @action close() {
    this.args.onClose();
  }

  get isSaveButtonDisabled(): boolean {
    return !this.hasEdited;
  }

  @action async updateCanUseMacros() {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (e) {
      return;
    }

    try {
      await this.copilotApi.updateSettings({ useMacros: this.useMacros });
      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.overview.sections.syncs.modals.manage-macros.toast-success'),
      );
      this.args.onClose();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.overview.sections.syncs.modals.manage-macros.toast-error'),
      );
      throw new Error('Failed to set useMacros', e.message);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ManageMacrosModal': typeof ManageMacrosModal;
    'knowledge-hub/manage-macros-modal': typeof ManageMacrosModal;
  }
}
