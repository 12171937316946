/* import __COLOCATED_TEMPLATE__ from './stamp.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  Element: HTMLElement;
  Args: {
    text: string;
    color: string;
    textStyle?: string;
    icon?: InterfaceIconName;
  };
}

interface StampColor {
  background: string;
  border: string;
  text: string;
}

const STAMP_COLOR_MAP = new Map<string, StampColor>([
  [
    'blue',
    {
      background: 'bg-blue',
      border: 'border-blue',
      text: 'text-white',
    },
  ],
  [
    'sky',
    {
      background: 'bg-sky',
      border: 'border-sky',
      text: 'text-blue',
    },
  ],
  [
    'transparent',
    {
      background: 'bg-transparent',
      border: 'border-shadow-dark',
      text: 'text-gray',
    },
  ],
  [
    'green',
    {
      background: 'bg-green-light',
      border: 'border-green-light',
      text: 'text-green-dark',
    },
  ],
  [
    'gray',
    {
      background: 'bg-gray-light',
      border: 'border-gray-light',
      text: 'text-gray',
    },
  ],
  [
    'yellow-light',
    {
      background: 'bg-yellow-light',
      border: 'border-yellow-light',
      text: 'text-yellow-dark',
    },
  ],
  [
    'yellow',
    {
      background: 'bg-yellow',
      border: 'border-yellow',
      text: 'text-yellow-dark',
    },
  ],
  [
    'red',
    {
      background: 'bg-red-light',
      border: 'border-red-light',
      text: 'text-red-dark',
    },
  ],
  [
    'paywall',
    {
      background: 'bg-paywall-light',
      border: 'bg-paywall-light',
      text: 'text-paywall-base',
    },
  ],
]);

const DEFAULT_OPTION_CLASS = STAMP_COLOR_MAP.get('sky');
export default class Stamp extends Component<Args> {
  get color() {
    return this.args.color ? STAMP_COLOR_MAP.get(this.args.color) : DEFAULT_OPTION_CLASS;
  }

  get textStyle() {
    if (isPresent(this.args.textStyle)) {
      return this.args.textStyle;
    }
    return this.color ? this.color.text : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Stamp': typeof Stamp;
  }
}
