/* import __COLOCATED_TEMPLATE__ from './confirm-delete-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import type Folder from 'embercom/models/content-service/folder';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';

interface Args {
  selectedFragments: Set<ContentFragment>;
  unselectedFragments: Set<ContentFragment>;
  isBulkSelection: boolean;
  isBulkActionRunning?: boolean;
  onClose: () => void;
  performBulkDelete: () => void;
}

export default class ConfirmDeleteModal extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare store: $TSFixMe;

  @tracked folderCount: number;
  @tracked contentCount: number;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.folderCount = 0;
    this.contentCount = 0;
    this.countSelectedItems();
  }

  get app() {
    return this.appService.app;
  }

  get workspaceHasSyncedContent(): boolean {
    return this.store.peekAll('content-service/folder').any((folder: Folder) => folder.isSynced);
  }

  countSelectedItems() {
    this.args.selectedFragments.forEach((fragment) => {
      if ((fragment.contentWrapper as KnowledgeHubContentWrapper).isEditable) {
        fragment.contentType === EntityType.ContentLibraryFolder
          ? (this.folderCount += 1)
          : (this.contentCount += 1);
      }
    });
  }

  get onlyContentSelected(): boolean {
    return this.contentCount > 0 && this.folderCount === 0 && !this.args.isBulkSelection;
  }

  get deleteButtonLabel() {
    if (this.args.isBulkSelection) {
      return this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.all');
    } else if (this.folderCount > 0) {
      return this.contentCount > 0
        ? this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.folder-and-content', {
            folderCount: this.folderCount,
            contentCount: this.contentCount,
          })
        : this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.folder', {
            folderCount: this.folderCount,
          });
    } else {
      return this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.content', {
        contentCount: this.contentCount,
      });
    }
  }

  get modalHeaderTitle() {
    return this.args.isBulkSelection && this.args.unselectedFragments.size > 0
      ? this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.selected-items')
      : this.deleteButtonLabel.concat('?');
  }

  get syncedText() {
    return this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.sync-content-text');
  }

  get publicArticleText() {
    return this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.public-article-text');
  }

  get modalBodyText() {
    if (this.folderCount > 0 || this.args.isBulkSelection) {
      return this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.folder-text');
    } else {
      return this.intl.t('knowledge-hub.filterable-list.bulk-actions.delete.content-text');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::BulkActions::ConfirmDeleteModal': typeof ConfirmDeleteModal;
    'knowledge-hub/filterable-list/bulk-actions/confirm-delete-modal': typeof ConfirmDeleteModal;
  }
}
