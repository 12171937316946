/* import __COLOCATED_TEMPLATE__ from './admin.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Admin from 'embercom/models/admin';
import { MAX_ADMINS_SHOWN_IN_TABLE_CELL } from 'embercom/lib/knowledge-hub/constants';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';

interface Args {
  adminIds: string[];
}

export default class AdminCell extends Component<Args> {
  @service store: any;
  @service declare intl: IntlService;
  @tracked maxAdminsShown = MAX_ADMINS_SHOWN_IN_TABLE_CELL;

  get admins(): Admin[] {
    let adminIds = this.args.adminIds;
    if (adminIds.length > MAX_ADMINS_SHOWN_IN_TABLE_CELL) {
      adminIds = adminIds.slice(0, MAX_ADMINS_SHOWN_IN_TABLE_CELL - 1);
    }
    return adminIds
      .filter((adminId) => adminId !== null)
      .map((adminId) => this.store.peekRecord('admin', adminId));
  }

  get firstAdmin(): Admin | null {
    if (this.args.adminIds.firstObject === null) {
      return null;
    }
    return this.store.peekRecord('admin', this.args.adminIds.firstObject);
  }

  get overflownAvatarsText(): string {
    // returns '+ n more' if num admins > than the max that can be shown
    if (this.args.adminIds.length > MAX_ADMINS_SHOWN_IN_TABLE_CELL) {
      return this.intl.t(`knowledge-hub.filterable-list.list.cells.admins.overflow`, {
        numOverflowAdmins: this.args.adminIds.length - MAX_ADMINS_SHOWN_IN_TABLE_CELL + 1,
      });
    } else {
      return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::Admin': typeof AdminCell;
    'knowledge-hub/filterable-list/table/cells/admin': typeof AdminCell;
  }
}
