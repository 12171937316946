/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { KnowledgeHubContentStatus } from 'embercom/lib/knowledge-hub/constants';
interface Args {
  states: KnowledgeHubContentStatus[];
}
export default class Status extends Component<Args> {
  stateWhenUsed = KnowledgeHubContentStatus.LIVE;

  get hasStatuses() {
    return (
      this.args.states && this.args.states.length > 0 && this.args.states.firstObject !== undefined
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::Status': typeof Status;
    'knowledge-hub/filterable-list/table/cells/status': typeof Status;
  }
}
