/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { KNOWLEDGE_HUB_DEFAULT_THEME_COLOR } from 'embercom/lib/knowledge-hub/constants';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';

interface Args {
  activeContent: LocalizedKnowledgeContent;
}

export default class Edit extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare appService: any;
  @service declare permissionsService: any;
  @tracked showSidePanel = true;

  get defaultThemeColor(): string {
    return KNOWLEDGE_HUB_DEFAULT_THEME_COLOR;
  }

  get editorConfig(): KnowledgeHubEditorConfig | undefined {
    return this.args.activeContent && this.args.activeContent.editorConfig
      ? this.args.activeContent.editorConfig
      : undefined;
  }

  get paywallConfig() {
    return this.knowledgeHubEditorService.paywallConfig;
  }

  @action
  toggleSidePanel(): void {
    this.showSidePanel = !this.showSidePanel;
    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${this.showSidePanel ? 'show' : 'hide'}_details_side_panel`,
    );
  }

  @action
  cancelEditActiveContent(): void {
    this.knowledgeHubEditorService.cancelEditActiveContent();
  }

  @dropTask *saveActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredEditPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.saveActiveContent();
  }

  @restartableTask *autoSaveActiveContent(): TaskGenerator<void> {
    if (
      !this.permissionsService.currentAdminCan(
        this.args.activeContent.requiredEditPermissionForKnowledgeHub,
      ) ||
      !this.args.activeContent?.autoSaveEnabled ||
      !this.args.activeContent?.hasUnsavedChanges ||
      !this.args.activeContent?.validations?.isValid
    ) {
      return;
    }

    yield timeout(ENV.APP._5000MS);

    yield this.knowledgeHubEditorService.autoSaveContent();
  }

  @dropTask
  *publishActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredPublishPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.publishActiveContent();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Edit': typeof Edit;
    'knowledge-hub/content-editor/edit': typeof Edit;
  }
}
