/* import __COLOCATED_TEMPLATE__ from './status-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { KnowledgeHubContentStatus } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  doSearch: (status?: string) => void;
  selectedStatusValue: string | null;
  activeFilter?: string;
  removeFilter: (status?: string) => void;
}

export default class StatusFilter extends Component<Args> {
  @service declare intl: IntlService;

  get isOpen() {
    return this.args.activeFilter === 'status' && !this.args.selectedStatusValue;
  }

  get status() {
    return [
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.status.live'),
        value: String(KnowledgeHubContentStatus.LIVE),
      },
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.status.draft'),
        value: String(KnowledgeHubContentStatus.DRAFT),
      },
    ];
  }

  get selectedStatus() {
    let status = this.status.find((status) => status.value === this.args.selectedStatusValue);
    return !status || !status.value
      ? this.intl.t('knowledge-hub.filterable-list.filters.status.any-status')
      : this.intl.t('knowledge-hub.filterable-list.filters.status.status-is', {
          status: status.text,
        });
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::StatusFilter': typeof StatusFilter;
    'knowledge-hub/filterable-list/filters/status-filter': typeof StatusFilter;
  }
}
