/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Invokable } from '@glint/template/-private/integration';
import { type ViewId } from 'embercom/components/knowledge-hub/setup-modal';
import { EntityType } from 'embercom/models/data/entity-types';
import { type KnowledgeUsageSummary, type SyncSourceType } from './overview/types';
import Conversations from './overview/sections/conversations';
import AiAgentConversations from './overview/sections/ai-agent-conversations';
import InternalArticles from './overview/sections/internal-articles';
import MoreSources from './overview/sections/more-sources';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import PublicArticles from './overview/sections/public-articles';
import Websites from './overview/sections/websites';
import type Store from '@ember-data/store';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import Macros from './overview/sections/macros';

export interface OverviewArgs {
  activeTab?: ContentFilter | undefined;
  showManageConversationsDrawer?: boolean;
  showManageMacrosModal?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: OverviewArgs;
}

export type ContentFilter = 'all' | 'ai-agent' | 'ai-copilot' | 'help-center';
const VALID_CONTENT_FILTERS: ContentFilter[] = ['all', 'ai-agent', 'ai-copilot', 'help-center'];

export const TEST_AI_AGENT_ROUTE = 'apps.app.fin-ai-agent.setup';
export const TEST_AI_COPILOT_ROUTE = 'apps.app.inbox';

const SOURCE_TYPE_TO_ENTITY_TYPE: Record<SyncSourceType | 'file-upload', EntityType> = {
  guru: EntityType.InternalArticle,
  confluence: EntityType.InternalArticle,
  notion: EntityType.InternalArticle,
  zendesk: EntityType.ArticleContent,
  external_content: EntityType.ExternalContent,
  zendesk_tickets: EntityType.Conversation,
  'file-upload': EntityType.FileSourceContent,
};

export interface OverviewSectionArgs {
  knowledgeUsageSummary: KnowledgeUsageSummary;
  contentFilter: ContentFilter;
  onAddSource: (sourceType: SyncSourceType | 'file-upload') => void;
  showManageConversationsDrawer?: boolean;
  showManageMacrosModal?: boolean;
}

export default class KnowledgeHubOverview extends Component<Signature> {
  @service declare appService: any;
  @service declare router: any;
  @service declare intl: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare intercomEventService: { trackAnalyticsEvent: Function };
  @service declare helpCenterService: $TSFixMe;
  @service declare store: Store;

  @tracked showSetupModal = false;
  @tracked setupModalEntryPoint?: ViewId = undefined;
  @tracked selectedEntityType: EntityType | null = null;
  @tracked contentFilter: ContentFilter = 'all';
  @tracked sectionsConfigs: {
    component: Invokable<any>;
    visibleFor: ContentFilter[];
  }[];

  public constructor(owner: any, args: any) {
    super(owner, args);
    if (this.args.activeTab && VALID_CONTENT_FILTERS.includes(this.args.activeTab)) {
      this.contentFilter = this.args.activeTab;
    }
    this.knowledgeHubService.subscribeToSourceSyncEvents();
    this.sectionsConfigs = [
      {
        component: PublicArticles,
        visibleFor: ['all', 'ai-agent', 'help-center'],
      },
      {
        component: InternalArticles,
        visibleFor: ['all', 'ai-copilot'],
      },
      {
        component: Conversations,
        visibleFor: ['all', 'ai-copilot'],
      },
      {
        component: Macros,
        visibleFor: ['all', 'ai-copilot'],
      },
      {
        component: PublicArticles,
        visibleFor: ['ai-copilot'],
      },
      {
        component: Websites,
        visibleFor: ['all', 'ai-copilot', 'ai-agent'],
      },
      {
        component: MoreSources,
        visibleFor: ['all', 'ai-copilot', 'ai-agent'],
      },
    ];
    if (this.appService.app.canUseAiAgentConversationsAlphaOptIn) {
      this.sectionsConfigs.unshift({
        component: AiAgentConversations,
        visibleFor: ['ai-agent'],
      });
    }
  }

  willDestroy() {
    super.willDestroy();
    this.knowledgeHubService.unsubscribeToSourceSyncEvents();
  }

  @action openSetupModalForEntity(entityType: EntityType) {
    this.showSetupModal = true;
    this.selectedEntityType = entityType;
  }

  @action changeTab(filter: ContentFilter) {
    this.contentFilter = filter;
    this.scrollToTop();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'tab',
      section: 'knowledge_hub',
      place: 'sources',
      tab: filter,
    });
  }

  private scrollToTop() {
    document.querySelector('.overview__main-container')?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @action async closeSetupModal() {
    this.showSetupModal = false;
    this.selectedEntityType = null;
    this.setupModalEntryPoint = undefined;
    await this.knowledgeHubService.removeEmptySources();
    this.knowledgeHubService.onSummaryChange();
  }

  get testFinOptions() {
    return [
      {
        items: [
          {
            text: this.intl.t('knowledge-hub.overview.test-fin-button.options.test-ai-agent'),
            onSelectItem: () => this.openFinAgentPage(),
          },
          {
            text: this.intl.t('knowledge-hub.overview.test-fin-button.options.test-ai-copilot'),
            onSelectItem: () => this.openFinCopilotPage(),
          },
        ],
      },
    ];
  }

  openFinAgentPage() {
    let agentUrl = this.router.urlFor(TEST_AI_AGENT_ROUTE);
    safeWindowOpen(agentUrl);
  }

  openFinCopilotPage() {
    let copilotUrl = this.router.urlFor(
      TEST_AI_COPILOT_ROUTE,
      { app_id: this.appService.app.id },
      {
        queryParams: { open_copilot: true },
      },
    );
    safeWindowOpen(copilotUrl);
  }

  get sectionComponents() {
    return this.sectionsConfigs.filter((section) =>
      section.visibleFor.includes(this.contentFilter),
    );
  }

  @action async showModal(syncSourceType: SyncSourceType | 'file-upload') {
    try {
      let entityType = SOURCE_TYPE_TO_ENTITY_TYPE[syncSourceType];
      await this.knowledgeHubService.ensurePermissions(entityType);
    } catch (error) {
      return;
    }
    switch (syncSourceType) {
      case 'guru':
        this.setupModalEntryPoint = 'guru-sync';
        break;
      case 'confluence':
        this.setupModalEntryPoint = 'confluence-sync';
        break;
      case 'notion':
        this.setupModalEntryPoint = 'notion-sync';
        break;
      case 'zendesk':
        this.setupModalEntryPoint = 'zendesk-sync';
        break;
      case 'external_content':
        this.setupModalEntryPoint = 'external-content';
        break;
      case 'file-upload':
        this.setupModalEntryPoint = 'file-upload';
        break;
      default:
        return;
    }
    this.showSetupModal = true;
  }

  get contentFilterIsValidForChecklist(): boolean {
    return this.contentFilter !== 'all' && this.contentFilter !== 'help-center';
  }

  get copilotFeatureAvailable(): boolean {
    return this.appService.app.canUseFeature('answerbot-inbox-copilot');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview': typeof KnowledgeHubOverview;
  }
}
