/* import __COLOCATED_TEMPLATE__ from './terms-and-conditions.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';

interface Args {}

export default class TermsAndConditions extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare contentImportService: ContentImportService;
  @service declare store: Store;
  @service declare router: RouterService;

  get app() {
    return this.appService.app;
  }

  get onboardingStep() {
    return this.store.peekRecord('onboarding/home/step', 'display_only_try_fin_for_yourself');
  }

  get showBanner(): boolean {
    if (this.app.hasConsentedToFinIngestion) {
      // This should catch basically all instances where app has accepted terms
      return false;
    }

    if (this.onboardingStep?.completed) {
      return false;
    }

    return true;
  }

  get termsAndConditionsUrl() {
    return 'https://www.intercom.com/legal/terms-and-policies/additional-product-terms';
  }

  get goToInboxUrl() {
    return this.router.urlFor('apps.app.inbox', { queryParams: { open_copilot: 'true' } });
  }

  get goToFinIngestionUrl() {
    return this.router.urlFor('apps.app.fin-ai-agent.setup');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Banners::TermsAndConditions': typeof TermsAndConditions;
  }
}
