/* import __COLOCATED_TEMPLATE__ from './data.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

import { inject as service } from '@ember/service';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    activeContent: LocalizedKnowledgeContent;
    showCreator?: boolean;
    showUpdater?: boolean;
    showWriter?: boolean;
    showStatus?: boolean;
  };
  Blocks: {
    default: any;
  };
}

/**
 * Provides a common data section for content in the knowledge hub.
 * If using the created by / updated by fields, you must ensure that `creatorId` / `updaterId` methods are overriden on the model.
 */
export default class DataSection extends Component<Signature> {
  @service declare permissionsService: $TSFixMe;

  @tracked languageCode = this.content.locale;

  get content() {
    return this.args.activeContent;
  }

  get allowSetLocale(): boolean {
    return !!this.args.activeContent.updateLanguageCode;
  }

  @action async setLocale(languageCode: string): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      this.languageCode = this.content.locale;
      return;
    }
    if (this.allowSetLocale) {
      this.languageCode = languageCode;
      await this.content.updateLanguageCode!(languageCode);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::Data': typeof DataSection;
    'knowledge-hub/content-editor/shared/data-section/data': typeof DataSection;
  }
}
