/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type KnowledgeUsageSummary, type ContentFilter } from '../types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';
import {
  AI_AGENT_ENTITY_TYPES,
  AI_COPILOT_ENTITY_TYPES,
} from 'embercom/lib/knowledge-hub/constants';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  currentTab: ContentFilter;
  knowledgeUsageSummary: KnowledgeUsageSummary;
  openSetupModal: () => void;
}

type ListItem = {
  name: string;
  done: boolean;
  onClick: () => void;
};

type Checklist = {
  all: ListItem[];
  'ai-agent': ListItem[];
  'ai-copilot': ListItem[];
  'help-center': ListItem[];
};

type AIType = 'agent' | 'copilot';

const MINIMUM_SOURCE_COUNT = 1;
const MINIMUM_CONVERSATION_EXCERPTS = 10;
const MINIMUM_OPTIMIZE_AI_SOURCE_COUNT = 2;

export default class Card extends Component<Args> {
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare router: RouterService;

  get checklistToRender() {
    return this.checklist()[this.args.currentTab];
  }

  get hasCompletedChecklist() {
    if (this.args.currentTab === 'ai-agent') {
      return (
        this.hasAddedMinimunRequiredSources('agent', MINIMUM_OPTIMIZE_AI_SOURCE_COUNT) &&
        this.knowledgeHubService.overviewChecklistAttributes.ai_agent_enabled
      );
    }

    return (
      this.hasAddedMinimunRequiredSources('copilot', MINIMUM_OPTIMIZE_AI_SOURCE_COUNT) &&
      this.knowledgeHubService.overviewChecklistAttributes.used_ai_copilot
    );
  }

  private checklist(): Checklist {
    return {
      all: [],
      'ai-agent': [
        this.addAtLeastOneSource('agent'),
        this.setUpAgentAndGoLive(),
        this.addMoreSources('agent'),
      ],
      'ai-copilot': [
        this.addAtLeastOneSource('copilot'),
        this.startUsingAICopilot(),
        this.addMoreSources('copilot'),
      ],
      'help-center': [],
    };
  }

  private addAtLeastOneSource(aiType: AIType): ListItem {
    return {
      name: this.intl.t('knowledge-hub.overview.getting-started-checklist.list.add-source'),
      done: this.hasAddedMinimunRequiredSources(aiType, MINIMUM_SOURCE_COUNT),
      onClick: this.args.openSetupModal,
    };
  }

  private addMoreSources(aiType: AIType): ListItem {
    return {
      name: this.intl.t(
        `knowledge-hub.overview.getting-started-checklist.list.optimize-ai-${aiType}`,
      ),
      done: this.hasAddedMinimunRequiredSources(aiType, MINIMUM_OPTIMIZE_AI_SOURCE_COUNT),
      onClick: this.args.openSetupModal,
    };
  }

  private setUpAgentAndGoLive(): ListItem {
    return {
      name: this.intl.t('knowledge-hub.overview.getting-started-checklist.list.test-ai-agent'),
      done: this.knowledgeHubService.overviewChecklistAttributes.ai_agent_enabled,
      onClick: () => {
        let url = this.router.urlFor('apps.app.fin-ai-agent.setup');
        safeWindowOpen(url, '_blank');
      },
    };
  }

  private startUsingAICopilot(): ListItem {
    return {
      name: this.intl.t(
        'knowledge-hub.overview.getting-started-checklist.list.start-using-ai-copilot',
      ),
      done: this.knowledgeHubService.overviewChecklistAttributes.used_ai_copilot,
      onClick: () => {
        let url = this.router.urlFor('apps.app.inbox', {
          queryParams: { open_copilot: true },
        });
        safeWindowOpen(url, '_blank');
      },
    };
  }

  private minimumCountForEntityType(entityType: EntityType): number {
    return entityType === EntityType.ConversationExcerpt
      ? MINIMUM_CONVERSATION_EXCERPTS
      : MINIMUM_SOURCE_COUNT;
  }

  private hasAddedMinimunRequiredSources(aiType: AIType, threshold: number): boolean {
    return this.getMinimumContentCount(aiType) >= threshold;
  }

  private getMinimumContentCount(aiType: AIType): number {
    let contentTypes = aiType === 'agent' ? AI_AGENT_ENTITY_TYPES : AI_COPILOT_ENTITY_TYPES;
    let count = 0;
    contentTypes.forEach((entityType) => {
      let summary = this.args.knowledgeUsageSummary[entityType];
      if (summary[aiType] >= this.minimumCountForEntityType(entityType)) {
        count++;
      }
    });
    return count;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::GettingStartedChecklist::Card': typeof Card;
    'knowledge-hub/overview/getting-started-checklist/card': typeof Card;
  }
}
