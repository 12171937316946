/* import __COLOCATED_TEMPLATE__ from './written-by.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

interface Args {
  activeContent: LocalizedKnowledgeContent;
}

const WrittenBy = templateOnlyComponent<Args>();
export default WrittenBy;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::WrittenBy': typeof WrittenBy;
    'knowledge-hub/content-editor/shared/data-section/written-by': typeof WrittenBy;
  }
}
