/* RESPONSIBLE TEAM: team-knowledge-interop */

import { DropActionType, Tree } from 'embercom/objects/tree-list';
import { TreeItem, type TreeParent } from 'embercom/objects/tree-list';
import Folder from 'embercom/models/content-service/folder';
import { type KnowledgeHubItem } from 'embercom/models/content-service/knowledge-hub-item';
import type Model from '@ember-data/model';
import { EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';

export let MAX_DEPTH = 10;

export const FolderTreeSettings = {
  childList: {
    classes: '',
  },
  listItem: {
    classes: 'flex flex-row items-center hover:bg-gray-light border-transparent border rounded',
    activeClasses: 'bg-sky-dark border-blue border-opacity-30',
  },
  insertionPoint: {
    classes: '',
    activeClasses: '',
    highlightParent: false,
    disabled: true,
  },
  dragProperties: {
    minDistanceInPX: 10,
    shortDraggingImage: true,
  },
  draggableContainer: {
    classes: 'flex flex-col',
  },
  animationEnabled: false,
};

export interface DidDropParams {
  intercomEventService: $TSFixMe;
}

const ANALYTIC_PLACE = 'folders_tree';
const DROP_ANALYTIC_ACTION = 'dropped';
const DROP_ANALYTIC_ACTION_ERROR = 'drop_error';

export async function didDropItem(
  item: TreeItem<KnowledgeHubItem & Model>,
  oldParent: TreeParent,
  dropActionType: DropActionType,
  { intercomEventService }: DidDropParams,
) {
  let currentObject = item.dataObject;
  currentObject.treeItem = item;
  if (currentObject.hasMoved({ oldParent })) {
    try {
      if (currentObject instanceof Folder) {
        currentObject.updateLocationToMatch({ treeItem: item as TreeItem<Folder> });
      }

      if (dropActionType === DropActionType.insideItem) {
        if (!(item.parent as TreeItem<Folder>).isExpanded) {
          (item.parent as TreeItem<Folder>).isExpanded = true;
        }
      }
      await currentObject.moveToFolderAndSave({
        folder: item.parent.isRoot ? undefined : (item.parent as TreeItem<Folder>).dataObject,
      });

      if (item.dataObject instanceof KnowledgeHubContentWrapper) {
        item.removeFromParent();
        // remove content wrapper from the list view and reload new parent for updating count
        (item.parent as TreeItem).dataObject?.reload();
      }
      item.removeContentWrapperFromList?.(currentObject.id);
      intercomEventService.trackAnalyticsEvent({
        action: DROP_ANALYTIC_ACTION,
        object: currentObject.entityName,
        place: ANALYTIC_PLACE,
      });
    } catch (e) {
      // remove the item from the parent as the operation failed and update dragging item's parent to old parent
      if (currentObject.entityType !== EntityType.ContentLibraryFolder) {
        item.removeFromParent();
        currentObject.parent = (oldParent as TreeItem<Folder>)?.dataObject;
      }
      intercomEventService.trackAnalyticsEvent({
        action: DROP_ANALYTIC_ACTION_ERROR,
        object: currentObject?.entityName,
        place: ANALYTIC_PLACE,
      });
      throw e;
    }
  }
}

export function orderFolders(folders: Folder[]) {
  if (folders.length === 0) {
    return [];
  } else if (folders.length === 1) {
    return folders;
  }

  return folders.sort((a, b) => compareFolderName(a, b));
}

export function canDropItem(draggingItem: TreeItem, targetParent: TreeParent) {
  let targetParentObject: KnowledgeHubItem | undefined = undefined;
  if (targetParent instanceof TreeItem) {
    targetParentObject = targetParent.dataObject;
  }
  return (
    (canMoveObject({ draggingObject: draggingItem.dataObject, targetParent: targetParentObject }) ||
      itemIsStayingAtRoot({ draggingItem, targetParent })) &&
    !isTargetParentSame(draggingItem, targetParent)
  );
}

export function canMoveObject({
  draggingObject,
  targetParent,
}: {
  draggingObject: KnowledgeHubItem;
  targetParent?: KnowledgeHubItem;
}) {
  return (
    objectIsEditable({ draggingObject }) &&
    targetParentIsEditable({ targetParent }) &&
    !objectExceedsMaxLevel({ draggingObject, targetParent })
  );
}

export function isTargetParentSame(draggingItem: TreeItem, targetParent: TreeParent) {
  if (
    draggingItem.dataObject.entityType === EntityType.ContentLibraryFolder ||
    draggingItem.parent.isRoot ||
    targetParent.isRoot
  ) {
    return false;
  }
  return draggingItem.parent === targetParent;
}

export function objectExceedsMaxLevel({
  draggingObject,
  targetParent,
}: {
  draggingObject: KnowledgeHubItem;
  targetParent?: KnowledgeHubItem;
}) {
  if (!targetParent) {
    // you can never exceed max level when moving to the tree root
    return false;
  }
  if (!(draggingObject instanceof Folder)) {
    // Content items don't add subfolders so are safe
    return false;
  }
  let targetParentFolder = targetParent as Folder;
  let targetParentFolderDepth = targetParentFolder.depth;
  let draggingObjectMaxBelow = draggingObject.maxSubfoldersBelow();
  let maxDepth = draggingObjectMaxBelow + targetParentFolderDepth + 1;

  if (maxDepth > MAX_DEPTH) {
    return true;
  }

  return false;
}

export function exceedsMaxLevel(targetParent: TreeParent, draggingItem: TreeItem): boolean {
  let targetParentObject: KnowledgeHubItem | undefined = undefined;
  if (targetParent instanceof TreeItem) {
    targetParentObject = targetParent.dataObject;
  }
  return objectExceedsMaxLevel({
    draggingObject: draggingItem.dataObject,
    targetParent: targetParentObject,
  });
}

function targetParentIsEditable({ targetParent }: { targetParent?: KnowledgeHubItem }) {
  return !targetParent || targetParent?.isEditable;
}

function objectIsEditable({ draggingObject }: { draggingObject: KnowledgeHubItem }) {
  return draggingObject.isEditable;
}

function itemIsStayingAtRoot({
  draggingItem,
  targetParent,
}: {
  draggingItem: TreeItem;
  targetParent: TreeParent;
}) {
  // allows synced folders to be organised at root
  return targetParent.isRoot && draggingItem.parent.isRoot;
}

export async function activeFolderFromModel(activeModel: any) {
  if (!activeModel) {
    return undefined;
  }
  if (activeModel instanceof Folder) {
    return activeModel;
  }
  try {
    // assume it's a content model
    let parentFolder = (await activeModel.folder) as Folder;
    return parentFolder;
  } catch (error) {
    // content is deleted
    return undefined;
  }
}

export async function folderIsActiveOnRoute(activeModel: any, folder: Folder) {
  let activeFolder = await activeFolderFromModel(activeModel);
  if (activeFolder) {
    return activeFolder.id === folder.id;
  }
  return false;
}

export function removeItemFromTree(treeItem: TreeItem) {
  // remove item from the parent
  treeItem.removeFromParent();

  if (treeItem.children && (treeItem.children?.length as number) > 0) {
    //add children TreeItems to new parent
    addTreeItemsToParent(treeItem.children?.toArray(), treeItem.parent);
  }
}

export function createTreeItem(
  parent: TreeParent,
  item: KnowledgeHubItem,
  canHaveChildren?: boolean,
  removeContentWrapperFromList?: (contentWrapperId: string) => void,
) {
  let treeItem = new TreeItem<KnowledgeHubItem>({
    tree: parent instanceof Tree ? parent : parent.tree,
    parent,
    children: [],
    isExpanded: false,
    canHaveChildren: canHaveChildren || true,
    dataObject: item,
    component: 'knowledge-hub/folders/tree/nav-list-item',
    removeContentWrapperFromList,
  });
  item.treeItem = treeItem;
  return treeItem;
}

export async function orderedRootFolders({
  knowledgeHubService,
  forceRefresh = false,
}: {
  knowledgeHubService: KnowledgeHubService;
  forceRefresh?: boolean;
}): Promise<Folder[]> {
  let folders = await knowledgeHubService.fetchFoldersOnce(forceRefresh);
  return orderFolders(rootFolders({ folders }).toArray());
}

function rootFolders({ folders }: { folders: Folder[] }) {
  let parentFolders = folders.filter((folder: Folder) => {
    return folder.isRoot;
  });
  return parentFolders;
}

export function indexForDropInside(draggingItem: TreeItem, targetItem: TreeItem): number {
  if (targetItem.children) {
    let index = getTreeItemIndex(draggingItem, targetItem.children.toArray());
    return index !== -1 ? index : targetItem.children.toArray().length;
  } else {
    return 0;
  }
}

export function getTreeItemIndex(treeItem: TreeItem, treeItems: TreeItem[]) {
  return treeItems.findIndex(
    (child) => compareFolderName(child.dataObject, treeItem.dataObject) > 0,
  );
}

export function compareFolderName(folderA: Folder, folderB: Folder) {
  return folderA.name.localeCompare(folderB.name);
}

function addTreeItemsToParent(treeItems: TreeItem[], parent: TreeParent) {
  treeItems.forEach((treeItem: TreeItem) => {
    addTreeItemToParent(treeItem, parent);
  });
}

export function addTreeItemToParent(treeItem: TreeItem, parent: TreeParent) {
  if (parent.children) {
    let index = getTreeItemIndex(treeItem, parent.children.toArray());
    parent.addChildAtIndex(treeItem, index);
  } else {
    parent.addChildren([treeItem]);
  }
}
