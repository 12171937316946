/* import __COLOCATED_TEMPLATE__ from './ai-agent-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { type OverviewSectionArgs } from '../../overview';
import { action } from '@ember/object';
import { AI_AGENT_CONVERSATIONS_SURVEY_ID } from 'embercom/lib/knowledge-hub/constants';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';

export const AI_AGENT_CONVOS_HAS_OPTED_IN = 'ai_agent_conversations_has_opted_in';

export default class AiAgentConversations extends Component<OverviewSectionArgs> {
  @service declare intl: IntlService;

  @trackedInLocalStorage({
    keyName: AI_AGENT_CONVOS_HAS_OPTED_IN,
  })
  optedInStoredValue: string | undefined;

  get hasOptedIn() {
    return this.optedInStoredValue === 'true';
  }

  @action
  onSectionButtonClick() {
    window.Intercom('startSurvey', AI_AGENT_CONVERSATIONS_SURVEY_ID);
    this.optedInStoredValue = 'true';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::AiAgentConversations': typeof AiAgentConversations;
    'knowledge-hub/overview/sections/ai-agent-conversations': typeof AiAgentConversations;
  }
}
