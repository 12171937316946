/* import __COLOCATED_TEMPLATE__ from './folder-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { modifier } from 'ember-modifier';

interface Args {
  label?: string;
  isDisabled: boolean;
  folders: FolderTreeItem[];
  selectedFolderIds?: string[];
  onSelectionChange?: (itemIds: Set<string>) => void;
}

export interface FolderTreeItem {
  id: string;
  title: string;
  level: number;
  parent_id: string | null;
  children_ids: string[];
}

export default class FolderSelector extends Component<Args> {
  @service declare intl: IntlService;

  @tracked selectedFolderIds: Set<string>;

  resetFolderSelection = modifier(() => {
    this.selectedFolderIds = new Set(this.args.selectedFolderIds ?? []);
  });

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.selectedFolderIds = new Set(args.selectedFolderIds ?? []);
  }

  get label(): string {
    return this.args.label ?? this.selectedFolderLabel;
  }

  get selectedFolderLabel() {
    if (this.selectedFolderIds.size === 0) {
      return this.intl.t('knowledge-hub.folder-selector.placeholder');
    }

    let firstFolder = this.treeItems.find((folder) => this.selectedFolderIds.has(folder.id));
    if (!firstFolder) {
      return this.intl.t('knowledge-hub.folder-selector.placeholder');
    }

    if (this.selectedFolderIds.size === 1) {
      return firstFolder.title;
    }

    return this.intl.t('knowledge-hub.folder-selector.selected-multiple-label', {
      folderName: firstFolder.title,
      count: this.selectedFolderIds.size - 1,
    });
  }

  private get treeItems(): FolderTreeItem[] {
    return this.args.folders;
  }

  private get treeItemsMap() {
    return this.treeItems.reduce(
      (acc, item) => {
        acc[item.id] = item;
        return acc;
      },
      {} as Record<string, FolderTreeItem>,
    );
  }

  private treeItemById(itemId: string | null) {
    if (!itemId) {
      return null;
    }
    return this.treeItemsMap[itemId];
  }

  get groupList() {
    let items = this.treeItems.map((item) => ({
      text: item.title,
      value: item.id,
      isSelected: this.selectedFolderIds.has(item.id),
      level: item.level,
      component: 'knowledge-hub/folder-selector/item',
    }));
    return [{ items }];
  }

  @action
  selectItem(itemId: string) {
    if (this.selectedFolderIds.has(itemId)) {
      this.removeItem(itemId);
    } else {
      this.addItem(itemId);
    }
    this.selectedFolderIds = this.selectedFolderIds;

    this.args.onSelectionChange?.(this.selectedFolderIds);
  }

  addItem(itemId: string, selectChildren = false) {
    this.selectedFolderIds.add(itemId);

    let item = this.treeItemById(itemId);
    if (!item) {
      return;
    }

    if (item.level === 1 || selectChildren) {
      for (let childId of item.children_ids) {
        this.addItem(childId, true);
      }
    } else {
      let ascendant = this.treeItemById(item.parent_id);
      while (ascendant) {
        this.selectedFolderIds.add(ascendant.id);
        ascendant = this.treeItemById(ascendant.parent_id);
      }
    }
  }

  removeItem(itemId: string) {
    this.selectedFolderIds.delete(itemId);

    let childIds = this.treeItemById(itemId)?.children_ids ?? [];
    for (let childId of childIds) {
      this.removeItem(childId);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FolderSelector': typeof FolderSelector;
    'knowledge-hub/folder-selector': typeof FolderSelector;
  }
}
