/* import __COLOCATED_TEMPLATE__ from './discovery-banner.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type ContentFilter } from '../overview';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  contentFilter: ContentFilter;
  hasLiveHelpCenter: boolean;
  helpCenterSettingsUrl: string;
}

const BANNER_IMAGE_URLS = {
  all: assetUrl('/assets/images/knowledge-hub/overview/all.png'),
  'ai-agent': assetUrl('/assets/images/knowledge-hub/overview/ai-agent.png'),
  'ai-copilot': assetUrl('/assets/images/knowledge-hub/overview/ai-copilot.png'),
  'help-center': assetUrl('/assets/images/knowledge-hub/overview/help-center.png'),
};

export default class DiscoveryBanner extends Component<Args> {
  get articleUrl() {
    let urls = {
      all: 'https://www.intercom.com/help/en/articles/9440354-knowledge-sources-to-power-ai-agents-and-self-serve-support',
      'ai-agent':
        'https://www.intercom.com/help/en/articles/9440354-knowledge-sources-to-power-ai-agents-and-self-serve-support#h_2d173730ca',
      'ai-copilot':
        'https://www.intercom.com/help/en/articles/9440354-knowledge-sources-to-power-ai-agents-and-self-serve-support#h_9a7905a0b8',
      'help-center':
        'https://www.intercom.com/help/en/articles/9440354-knowledge-sources-to-power-ai-agents-and-self-serve-support#h_49bacbfb80',
    };
    return urls[this.args.contentFilter];
  }

  get bannerImage() {
    return BANNER_IMAGE_URLS[this.args.contentFilter];
  }

  @action openHelpCenter() {
    safeWindowOpen(this.args.helpCenterSettingsUrl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::DiscoveryBanner': typeof DiscoveryBanner;
    'knowledge-hub/overview/discovery-banner': typeof DiscoveryBanner;
  }
}
