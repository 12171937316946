/* import __COLOCATED_TEMPLATE__ from './language-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type ArticleContent from 'embercom/models/articles/article-content';
import type Router from '@ember/routing/router-service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { isPresent } from '@ember/utils';
import BrowserLocales from 'embercom/lib/browser-locales';
import { FIN_SUPPORTED_LANGUAGES } from 'embercom/lib/ai-content-library/constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  activeContent: ArticleContent;
}

export default class LanguageSelector extends Component<Args> {
  @service declare helpCenterService: any;
  @service declare router: Router;
  @service declare store: Store;
  @service declare notificationsService: {
    notifyConfirmation: Function;
    notifyError: Function;
  };
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;

  get hasHelpCenter() {
    return this.helpCenterService.allSites.length > 0;
  }

  get article() {
    return this.args.activeContent.article;
  }

  get availableLocales() {
    let availableLocales: Array<{ name: string; localeId: string }> =
      this.helpCenterService.allUniqueLocalesOrdered;

    // Readonly articles should hide missing locales as creation of new article contents is not allowed.
    if (!this.args.activeContent.isEditable) {
      availableLocales = availableLocales.filter((locale) => {
        return isPresent(this.article.contentByLocale(locale.localeId));
      });
    }

    return [
      ...this.activeContentLocales(this.article.existingLocales),
      ...availableLocales.filter(
        (locale) => !this.article.existingLocales.includes(locale.localeId),
      ),
    ];
  }

  private activeContentLocales(locales: string[]) {
    return locales.map((locale: string) => {
      let name =
        BrowserLocales[locale] ||
        FIN_SUPPORTED_LANGUAGES.find((lang) => lang.locale === locale)?.name;
      return {
        name,
        localeId: locale,
      };
    });
  }

  get activeLocale() {
    return this.languageOptions.find((locale) => this.args.activeContent.locale === locale.value);
  }

  get languageOptions() {
    return this.availableLocales.map((locale) => ({
      text: locale.name,
      value: locale.localeId,
      state: this.article.contentByLocale(locale.localeId)?.state || 'missing',
      component: 'knowledge-hub/content-editor/article-content/language-selector-option',
    }));
  }

  @action
  async onLocaleClick(localeId: string) {
    let content = this.article.contentByLocale(localeId);
    if (content) {
      this.router.transitionTo('apps.app.knowledge-hub.view', 'article', content.id);
    } else {
      try {
        await this.knowledgeHubService.ensurePermissions(EntityType.ArticleContent);
      } catch (error) {
        return;
      }
      try {
        let newArticleContent = await this.article.createContentWithVersionIfNone(localeId);
        this.router.transitionTo('apps.app.knowledge-hub.edit', 'article', newArticleContent!.id);
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.content-editor.edit.create-translation.success'),
        );
      } catch (error) {
        this.notificationsService.notifyError(
          this.intl.t('knowledge-hub.content-editor.edit.create-translation.error'),
        );
      }
    }
  }

  @action
  redirectToHelpCenterLanguageSettings() {
    this.router.transitionTo(
      'apps.app.articles.site.settings.setting',
      this.helpCenterService.site.id,
      {
        queryParams: { sectionId: 'language' },
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::LanguageSelector': typeof LanguageSelector;
    'knowledge-hub/content-editor/article-content/language-selector': typeof LanguageSelector;
  }
}
