/* import __COLOCATED_TEMPLATE__ from './visibility-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  setChatbotAvailability,
  setCopilotAvailability,
} from 'embercom/models/content-service/ai-consumable';
import { inject as service } from '@ember/service';
import type ArticleContent from 'embercom/models/articles/article-content';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';

interface Args {
  activeContent: ArticleContent;
}

type FinSettingType = 'copilot' | 'chatbot';

export default class VisibilitySection extends Component<Args> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare store: any;
  @tracked newCopilotState: boolean | null = null;
  @tracked newChatbotState: boolean | null = null;

  get article(): ArticleMultilingual {
    return this.store.peekRecord(
      'article-multilingual',
      this.args.activeContent.articleId,
    ) as ArticleMultilingual;
  }

  get allArticleContents(): ArticleContent[] {
    return this.article.articleContents.toArray();
  }

  get isMultiLingual(): boolean {
    return this.allArticleContents.length > 1;
  }

  @action showCopilotConfirmation(available: boolean) {
    this.newCopilotState = available;
  }

  @action showChatbotConfirmation(available: boolean) {
    this.newChatbotState = available;
  }

  @action resetCopilotState() {
    this.newCopilotState = null;
  }

  @action resetChatbotState() {
    this.newChatbotState = null;
  }

  get copilotModalIntlPrefix(): string {
    return this.newCopilotState
      ? 'knowledge-hub.content-editor.article-content.visibility-section.confirmation-modal.copilot.enable'
      : 'knowledge-hub.content-editor.article-content.visibility-section.confirmation-modal.copilot.disable';
  }

  get chatbotModalIntlPrefix(): string {
    return this.newChatbotState
      ? 'knowledge-hub.content-editor.article-content.visibility-section.confirmation-modal.chatbot.enable'
      : 'knowledge-hub.content-editor.article-content.visibility-section.confirmation-modal.chatbot.disable';
  }

  @action
  async setMultilingualAvailability(finSettingType: FinSettingType) {
    let newFinState = finSettingType === 'chatbot' ? this.newChatbotState : this.newCopilotState;
    if (newFinState === null) {
      return;
    }

    let contentsToToggle: ArticleContent[] = this.allArticleContents;
    if (newFinState) {
      // only when toggling on, when toggling off we do it for all, to ensure that all contents have the same state
      contentsToToggle = await this.filterEligibleContents(contentsToToggle, finSettingType);
    }
    if (finSettingType === 'chatbot') {
      await setChatbotAvailability(this.appService.app.id, contentsToToggle, newFinState);
    } else {
      await setCopilotAvailability(this.appService.app.id, contentsToToggle, newFinState);
    }
    if (!newFinState) {
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          `knowledge-hub.content-editor.article-content.visibility-section.${finSettingType}-disabled`,
        ),
      );
    } else if (contentsToToggle.length < this.allArticleContents.length) {
      this.notificationsService.notifyWarning(
        this.intl.t(
          `knowledge-hub.content-editor.article-content.visibility-section.${finSettingType}-partial-enabled`,
        ),
      );
    } else {
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          `knowledge-hub.content-editor.article-content.visibility-section.${finSettingType}-enabled`,
        ),
      );
    }
    if (finSettingType === 'chatbot') {
      this.resetChatbotState();
    } else {
      this.resetCopilotState();
    }
  }

  async filterEligibleContents(
    contents: ArticleContent[],
    finSettingType: FinSettingType,
  ): Promise<ArticleContent[]> {
    let filteredContent = [];
    for (let content of contents) {
      let isEligible =
        finSettingType === 'chatbot'
          ? await content.isEligibleForChatbot()
          : await content.isEligibleForCopilot();
      if (isEligible) {
        filteredContent.push(content);
      }
    }
    return filteredContent;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::VisibilitySection': typeof VisibilitySection;
    'knowledge-hub/content-editor/article-content/visibility-section': typeof VisibilitySection;
  }
}
