/* import __COLOCATED_TEMPLATE__ from './item-container.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Folder from 'embercom/models/content-service/folder';
import { type TreeItem } from 'embercom/objects/tree-list';
import { action } from '@ember/object';
import type FolderMembership from 'embercom/models/content-service/folder-membership';
import type RouterService from '@ember/routing/router-service';

interface Args {
  item: TreeItem<Folder | FolderMembership | undefined>;
  isActive?: boolean;
  activeBackgroundColor?: string;
}
interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class ItemContainer extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare router: RouterService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get isActive() {
    return this.args.isActive || this.args.item.isActive;
  }

  get backgroundColor(): string {
    if (this.isActive) {
      return this.args.activeBackgroundColor ?? 'bg-white';
    }
    return '';
  }

  get folder(): Folder | undefined {
    if (this.args.item.dataObject instanceof Folder) {
      return this.args.item.dataObject;
    }
    return undefined;
  }

  get grayedOut() {
    if (this.draggingItem) {
      return this.folder?.isSynced || this.args.item.isThisOrLevelAboveDraggingItem;
    }
    return false;
  }

  get draggingItem() {
    return this.args.item.tree.draggingItem;
  }

  get id() {
    return this.folder?.id;
  }

  get indentationPadding() {
    return `folder-depth-${this.args.item.depth}`;
  }

  @action
  toggleIsExpanded(event: MouseEvent) {
    this.args.item.isExpanded = !this.args.item.isExpanded;

    this.intercomEventService.trackAnalyticsEvent({
      action: this.args.item.isExpanded ? 'expanded' : 'collapsed',
      object: this.args.item.dataObject,
      place: 'knowledge-hub',
      section: 'folders-tree-list',
    });
    event.stopPropagation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::Tree::ItemContainer': typeof ItemContainer;
    'knowledge-hub/folders/tree/item-container': typeof ItemContainer;
  }
}
