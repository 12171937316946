/* import __COLOCATED_TEMPLATE__ from './help-center-name.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';
import KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import ContentFragment from 'embercom/models/content-service/content-fragment';

interface Args {
  tableRow: KnowledgeHubContentWrapper | ContentFragment;
}

const NUMBER_OF_HELP_CENTERS_TO_DISPLAY = 2;

export default class HelpCenterName extends Component<Args> {
  @service declare helpCenterService: $TSFixMe;

  get helpCenterSites(): HelpCenterSite[] {
    return this.helpCenterService.allSites;
  }

  get helpCenterIds(): number[] {
    if (this.args.tableRow instanceof KnowledgeHubContentWrapper) {
      return this.args.tableRow.contents.firstObject!.contentData?.help_center_ids ?? [];
    }
    if (this.args.tableRow instanceof ContentFragment) {
      return this.args.tableRow.contentData?.help_center_ids ?? [];
    }
    return [];
  }

  get helpCenterNames(): string[] {
    let names = this.helpCenterIds.map(
      (id: number) =>
        this.helpCenterSites.find((helpCenter: HelpCenterSite) => helpCenter.id === String(id))
          ?.name ?? undefined,
    );
    return names.compact();
  }

  get helpCenterNamesToDisplay(): string {
    return this.helpCenterNames.slice(0, NUMBER_OF_HELP_CENTERS_TO_DISPLAY).join(', ');
  }

  get moreCount(): number {
    return this.helpCenterIds.length - NUMBER_OF_HELP_CENTERS_TO_DISPLAY;
  }

  get moreHelpCenterNames(): string[] {
    return this.helpCenterNames.slice(
      NUMBER_OF_HELP_CENTERS_TO_DISPLAY,
      this.helpCenterNames.length,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::HelpCenterName': typeof HelpCenterName;
    'knowledge-hub/filterable-list/table/cells/help-center-name': typeof HelpCenterName;
  }
}
