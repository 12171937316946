/* RESPONSIBLE TEAM: team-knowledge-interop */
import { EntityType } from 'embercom/models/data/entity-types';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type IntlService from 'ember-intl/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { FIN_SUPPORTED_LANGUAGES, type Language } from 'embercom/lib/ai-content-library/constants';
import { ARTICLES_FEATURE, MULTILINGUAL_ARTICLES_FEATURE } from 'embercom/lib/billing';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
export interface ContentFragmentTuple {
  entity_id: number;
  entity_type: number;
}

export interface BulkActionApiParams extends AudienceBulkActionApiParams {
  app_id: number;
  is_bulk_selection: boolean;
  contents_to_update: ContentFragmentTuple[] | null;
  unselected_tuples: ContentFragmentTuple[] | null;
  query: any;
  language_code?: string;
  destination_folder_id?: number | string;
}

export interface AudienceBulkActionApiParams {
  segment_ids_to_add?: number[] | null;
  segment_ids_to_delete?: number[] | null;
  remove_all_segments?: boolean;
}

export interface DropdownOption {
  text: string;
  value: string;
}

export interface OptionItem {
  icon?: string;
  value?: string;
  text?: string;
  onSelectItem?: () => void;
  component?: string;
  groupList?: Array<{ items: DropdownOption[] }>;
  componentShouldReplaceItem?: boolean;
}

export enum ActionName {
  ArticlePublish = 'article-publish',
  ArticleUnpublish = 'article-unpublish',
  AICopilotEnable = 'ai-copilot-enable',
  AICopilotDisable = 'ai-copilot-disable',
  AIAgentEnable = 'ai-agent-enable',
  AIAgentDisable = 'ai-agent-disable',
  UpdateLanguage = 'update-language',
  MoveToFolder = 'move-to-folder',
  UpdateAudience = 'update-audience',
  Delete = 'delete',
}

export const actionParameters: {
  [key in ActionName]: {
    endpoint: string;
    applicableContentTypes?: number[];
    requiredPermission: string;
  };
} = {
  [ActionName.ArticlePublish]: {
    endpoint: 'bulk_publish',
    applicableContentTypes: [EntityType.ArticleContent],
    requiredPermission: CAN_MANAGE_ARTICLES_PERMISSION,
  },
  [ActionName.ArticleUnpublish]: {
    endpoint: 'bulk_unpublish',
    applicableContentTypes: [EntityType.ArticleContent],
    requiredPermission: CAN_MANAGE_ARTICLES_PERMISSION,
  },
  [ActionName.AICopilotEnable]: {
    endpoint: 'bulk_enable_copilot_availability',
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
    applicableContentTypes: [
      EntityType.ArticleContent,
      EntityType.InternalArticle,
      EntityType.ExternalContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
    ],
  },
  [ActionName.AICopilotDisable]: {
    endpoint: 'bulk_disable_copilot_availability',
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
    applicableContentTypes: [
      EntityType.ArticleContent,
      EntityType.InternalArticle,
      EntityType.ExternalContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
    ],
  },
  [ActionName.AIAgentEnable]: {
    endpoint: 'bulk_enable_chatbot_availability',
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
    applicableContentTypes: [
      EntityType.ArticleContent,
      EntityType.ExternalContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
    ],
  },
  [ActionName.AIAgentDisable]: {
    endpoint: 'bulk_disable_chatbot_availability',
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
    applicableContentTypes: [
      EntityType.ArticleContent,
      EntityType.ExternalContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
    ],
  },
  [ActionName.UpdateLanguage]: {
    endpoint: 'bulk_update_language',
    applicableContentTypes: [
      EntityType.InternalArticle,
      EntityType.ExternalContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
    ],
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  },
  [ActionName.MoveToFolder]: {
    endpoint: 'bulk_move_to_folder',
    applicableContentTypes: [
      EntityType.InternalArticle,
      EntityType.ArticleContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
      EntityType.ContentLibraryFolder,
    ],
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  },
  [ActionName.UpdateAudience]: {
    endpoint: 'bulk_change_segment',
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
    applicableContentTypes: [
      EntityType.ArticleContent,
      EntityType.InternalArticle,
      EntityType.ExternalContent,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
    ],
  },
  [ActionName.Delete]: {
    endpoint: 'bulk_delete',
    requiredPermission: CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
    applicableContentTypes: [
      EntityType.ArticleContent,
      EntityType.InternalArticle,
      EntityType.ContentSnippet,
      EntityType.FileSourceContent,
      EntityType.ContentLibraryFolder,
    ],
  },
};

function languageOptions(applyLanguage: (locale: string) => void): Array<DropdownOption> {
  return FIN_SUPPORTED_LANGUAGES.map((language: Language) => {
    return {
      text: language.name,
      value: language.locale,
      onSelectItem: () => applyLanguage(language.locale),
    };
  });
}

export type BulkActionConfig = {
  id: string;
  label?: string;
  visible: boolean;
  subtle?: boolean;
  icon?: InterfaceIconName;
  options: {
    items: OptionItem[];
  }[];
};

export function bulkActions(
  intl: IntlService,
  isBulkSelection: boolean,
  selectedFragments: Set<ContentFragment>,
  openMoveToFolderModal: () => void,
  applyLanguage: (locale: string) => void,
  applyBulkAction: (actionName: ActionName) => void,
  applyAudience: (audienceBulkActionApiParams: AudienceBulkActionApiParams) => void,
  openConfirmDeleteModal: () => void,
  app: any,
): BulkActionConfig[] {
  return [
    {
      id: 'change-ai-agent-state',
      label: intl.t('knowledge-hub.filterable-list.bulk-actions.dropdown.change-ai-agent-state'),
      icon: 'fin',
      visible: isApplicableForContent(
        ActionName.AIAgentEnable,
        isBulkSelection,
        selectedFragments,
        app,
      ),
      options: [
        {
          items: [
            {
              icon: 'check',
              value: ActionName.AIAgentEnable,
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.ai-agent-enable.label'),
            },
            {
              icon: 'x',
              value: ActionName.AIAgentDisable,
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.ai-agent-disable.label'),
            },
          ],
        },
      ],
    },
    {
      id: 'change-ai-copilot-state',
      label: intl.t('knowledge-hub.filterable-list.bulk-actions.dropdown.change-ai-copilot-state'),
      icon: 'fin',
      visible: isApplicableForContent(
        ActionName.AICopilotEnable,
        isBulkSelection,
        selectedFragments,
        app,
      ),
      options: [
        {
          items: [
            {
              icon: 'check',
              value: ActionName.AICopilotEnable,
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.ai-copilot-enable.label'),
            },
            {
              icon: 'x',
              value: ActionName.AICopilotDisable,
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.ai-copilot-disable.label'),
            },
          ],
        },
      ],
    },
    {
      id: 'more',
      icon: 'new',
      subtle: true,
      label: intl.t('knowledge-hub.filterable-list.bulk-actions.dropdown.actions'),
      visible: true,
      options: [
        {
          items: [
            {
              action: ActionName.MoveToFolder,
              icon: 'move-folder',
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.move-to-folder.label'),
              onSelectItem: () => openMoveToFolderModal(),
            },
            {
              action: ActionName.UpdateAudience,
              component: 'knowledge-hub/filterable-list/bulk-actions/audience-dropdown',
              onSelectItem: () => {},
              componentShouldReplaceItem: true,
              selectedFragments,
              applyAudience,
            },
            {
              action: ActionName.ArticlePublish,
              component: 'knowledge-hub/filterable-list/bulk-actions/sub-dropdown',
              icon: 'article',
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.dropdown.change-hc-state'),
              onSelectItem: () => {},
              componentShouldReplaceItem: true,
              groupList: [
                {
                  items: [
                    {
                      icon: 'check',
                      value: ActionName.ArticlePublish,
                      text: intl.t(
                        'knowledge-hub.filterable-list.bulk-actions.article-publish.label',
                      ),
                      onSelectItem: () => applyBulkAction(ActionName.ArticlePublish),
                    },
                    {
                      icon: 'hidden',
                      value: ActionName.ArticleUnpublish,
                      text: intl.t(
                        'knowledge-hub.filterable-list.bulk-actions.article-unpublish.label',
                      ),
                      onSelectItem: () => applyBulkAction(ActionName.ArticleUnpublish),
                    },
                  ],
                },
              ],
            },
            {
              action: ActionName.UpdateLanguage,
              component: 'knowledge-hub/filterable-list/bulk-actions/sub-dropdown',
              groupList: [
                {
                  items: languageOptions(applyLanguage),
                },
              ],
              icon: 'language',
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.dropdown.change-language'),
              onSelectItem: () => {},
              componentShouldReplaceItem: true,
            },
            {
              action: ActionName.Delete,
              icon: 'trash',
              isDestructive: true,
              text: intl.t('knowledge-hub.filterable-list.bulk-actions.delete.label'),
              onSelectItem: () => openConfirmDeleteModal(),
            },
          ].filter((item) =>
            isApplicableForContent(item.action, isBulkSelection, selectedFragments, app),
          ),
        },
      ],
    },
  ];
}

export function onlyFolderActionApplicable(
  selectedFragments: Set<ContentFragment>,
  contentTypeFilter?: Array<EntityType>,
) {
  if (listIsFilteredByOnlyFolder(contentTypeFilter)) {
    return true;
  }

  let contentTypes = selectedContentTypes(selectedFragments);
  if (contentTypes.size === 1 && contentTypes.has(EntityType.ContentLibraryFolder)) {
    return true;
  }
  return false;
}

function selectedContentTypes(selectedFragments: Set<ContentFragment>): Set<EntityType> {
  let selectedContentTypes = Array.from(selectedFragments).map((fragment) => fragment.contentType);
  return new Set<EntityType>(selectedContentTypes);
}

function listIsFilteredByOnlyFolder(contentTypeFilter?: Array<EntityType>) {
  if (contentTypeFilter?.length === 1 && contentTypeFilter[0] === EntityType.ContentLibraryFolder) {
    return true;
  }
  return false;
}

function isApplicableForContent(
  actionName: ActionName,
  isBulkSelection: boolean,
  selectedFragments: Set<ContentFragment>,
  app: any,
): boolean {
  let applicableContentTypes = actionParameters[actionName].applicableContentTypes;
  if (!app.canUseKnowledgeBulkDelete && actionName === ActionName.Delete) {
    return false;
  }

  if (!applicableContentTypes || isBulkSelection) {
    return true;
  }

  let selectedContentTypes = Array.from(selectedFragments).map((fragment) => fragment.contentType);
  if (!app.canUseAudienceForCopilot && actionName === ActionName.UpdateAudience) {
    applicableContentTypes = applicableContentTypes.filter(
      (contentType) => contentType !== EntityType.InternalArticle,
    );
  }
  if (actionName === ActionName.ArticlePublish) {
    if (!app.canUseFeature(ARTICLES_FEATURE)) {
      return false;
    } else if (!app.canUseFeature(MULTILINGUAL_ARTICLES_FEATURE)) {
      return Array.from(selectedFragments).any((fragment: ContentFragment) => {
        let contentWrapper = fragment.contentWrapper as KnowledgeHubContentWrapper;
        let isDefaultLanguageSelected =
          fragment.contentId === contentWrapper?.defaultContent.contentId;
        return isDefaultLanguageSelected;
      });
    }
  }
  return selectedContentTypes.any((contentType) => applicableContentTypes!.includes(contentType));
}
